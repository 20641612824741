import * as React from "react";

function SvgSuperUser(props) {
  return (
    <svg width={19} height={21} {...props}>
      <path
        d="M9.18 0c3.151 0 6.255.91 8.977 2.631l.245.154v.52c0 7.053-3.417 13.632-8.917 17.172l-.284.183-.284-.183C3.416 16.937 0 10.357 0 3.305c0-.107 0-.215.015-.322l.031-.245.209-.131A16.664 16.664 0 019.179 0zm.684 11.496h-1.22a3.821 3.821 0 00-3.811 3.654c1.212 1.668 2.679 3.12 4.368 4.257 1.736-1.17 3.237-2.67 4.468-4.397a3.823 3.823 0 00-3.805-3.514zM9.18 1.05a15.6 15.6 0 00-8.129 2.3c.008 3.813 1.069 7.473 2.94 10.537a4.878 4.878 0 014.654-3.44h1.22a4.874 4.874 0 014.617 3.33c1.825-3.037 2.86-6.648 2.87-10.41C14.859 1.85 12.039 1.05 9.18 1.05zm.133 2.973a3.008 3.008 0 013.004 3.004 3.008 3.008 0 01-3.004 3.004 3.007 3.007 0 01-3.004-3.004 3.007 3.007 0 013.004-3.004zm0 1.05a1.956 1.956 0 00-1.954 1.954c0 1.078.876 1.954 1.954 1.954a1.956 1.956 0 001.954-1.954 1.957 1.957 0 00-1.954-1.954z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgSuperUser;
