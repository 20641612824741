import * as React from "react";

function SvgInfoStroke(props) {
  return (
    <svg width={20} height={20} {...props}>
      <path
        d="M9.667 0c5.338 0 9.666 4.328 9.666 9.667 0 5.338-4.328 9.666-9.666 9.666C4.328 19.333 0 15.005 0 9.667 0 4.328 4.328 0 9.667 0zm0 1a8.667 8.667 0 100 17.334A8.667 8.667 0 009.667 1zm.89 7.61v6.667H8.89V8.611h1.666zM9.65 4.424c.174 0 .337.034.49.1a1.292 1.292 0 01.673.667 1.205 1.205 0 010 .974 1.309 1.309 0 01-.673.659 1.224 1.224 0 01-1.36-.267 1.292 1.292 0 01-.266-.392 1.247 1.247 0 010-.974 1.263 1.263 0 01.657-.666c.15-.067.31-.1.479-.1z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgInfoStroke;
